import React from 'react';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import Box, { Spacing } from '@webAtoms/Box/Box';
import Button from '@webAtoms/Button/Button';
import Container from '@webAtoms/Container/Container';
import BrochureForm from '@webSections/BrochureForm';
import Accordion from '@webMolecules/Accordion/Accordion';
import UpNext from '@webMolecules/UpNext/UpNext';
import ModularHomeDesigns from '@webOrganisms/ModularHomeDesigns/ModularHomeDesigns';
import FeaturedBuilds from '@webSections/FeaturedBuilds';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import * as Route from '@routes';
import * as styles from './careers.module.scss';
import { render } from '@helpers/markdown';

export interface CareersProps {
  title: string;
  body: string;
  heroImage: IGatsbyImageData;
  vacancies: Section[];
  benefits: Section[];
}

export interface Section {
  title: string;
  parts: Part[];
}

export interface Part {
  title: string;
  body: string;
}

const Careers: React.FC<CareersProps> = ({
  title,
  body,
  heroImage,
  benefits,
  vacancies,
}) => {
  return (
    <Box>
      <PageHeading title={title}>
        <Box className={styles.careersBody} htmlContent={body} />
      </PageHeading>
      <Container>
        <Box className={styles.careersInner}>
          <Box className={styles.careersHeroImageWrapper}>
            <GatsbyImage loading="lazy" alt={title} image={heroImage} />
          </Box>

          <Box className={styles.careersGrid}>
          <Box className={styles.careersBenefits}>
              {benefits.map((section, i) => (
                <Box key={i} className={styles.careersBenefitsSection}>
                  <Box className={styles.careersBenefitsSectionInner}>
                    <h3>{section.title}</h3>
                    <ul className={styles.careersBenefitsList}>
                      {section.parts.map(part => (
                        <li key={part.title}>
                          <Box className={styles.careersBenefitsListBody}>
                            <h4>{part.title}</h4>
                            <Box htmlContent={render(part.body)} />
                          </Box>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box className={styles.careersVacancies}>
              {vacancies.map((section, i) => (
                <Box key={i} className={styles.careersVacanciesSection}>
                  <Box className={styles.careersVacanciesSectionInner}>
                    <h2>{section.title}</h2>
                    <ul className={styles.careersVacanciesList}>
                      {section.parts.map(part => (
                        <li key={part.title}>
                          <Accordion title={part.title}>
                            <Box
                              className={styles.careersVacanciesListBody}
                              htmlContent={render(part.body)}
                            />
                          </Accordion>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Box mobile paddingTop={Spacing.Xl}>
          <Button
            fluid
            intent="primary"
            icon="arrowRight"
            href={Route.contactUs()}
          >
            Enquire
          </Button>
        </Box>
      </Container>

      <Box paddingTop={Spacing.Xl2} paddingBottom={Spacing.Xl2}>
        <BrochureForm />
      </Box>

      <FeaturedBuilds />

      <ModularHomeDesigns />

      <UpNext
        links={[
          { label: 'Our approach', href: Route.ourApproach() },
          {
            label: 'Modular homes',
            href: Route.designs(),
          },
        ]}
      />
    </Box>
  );
};

export default Careers;
