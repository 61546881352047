import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { PageCareersQuery } from '@generated/GraphqlTypes';
import Base from '@webLayouts/Base/Base';
import Careers from '@webPages/Careers/Careers';

const CareersPage: React.FC<PageProps<PageCareersQuery>> = ({ data }) => {
  const title = data?.careers?.frontmatter?.title ?? '';

  const seoTitle = data?.careers?.frontmatter?.seoTitle ?? title ?? undefined;
  const seoDescription =
    data?.careers?.frontmatter?.seoDescription ??
    data?.careers?.excerpt ??
    undefined;

  const image = data?.careers?.frontmatter?.heroImage?.publicURL ?? undefined;

  const vacancies =  (data?.careers?.frontmatter?.vacancies ?? []).map(
    vacancies => ({
      title: vacancies?.title ?? '',
      parts: (vacancies?.parts ?? []).map(
        (part: any) => ({
          title: part?.title ?? '',
          body: part?.body ?? '',
        })
      ),
    })
  );

  const benefits =  (data?.careers?.frontmatter?.benefits ?? []).map(
    benefits => ({
      title: benefits?.title ?? '',
      parts: (benefits?.parts ?? []).map(
        (part: any) => ({
          title: part?.title ?? '',
          body: part?.body ?? '',
        })
      ),
    })
  );
  
  const careers = {
    title,
    body: data?.careers?.html ?? '',
    heroImage: getImage(
      data?.careers?.frontmatter?.heroImage as ImageDataLike
    ) as IGatsbyImageData,
    vacancies,
    benefits,
  };

  return (
    <Base
      theme="light"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <Careers {...careers} />
    </Base>
  );

 
};

export const query = graphql`
  query PageCareers {
    careers: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "careers" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription
        heroImage {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              height: 650
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }

        vacancies {
          title
          parts {
            title
            body
          }
        }

        benefits {
          title
          parts {
            title
            body
          }
        }
      }
    }
  }
`;

export default CareersPage;
